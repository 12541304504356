/*********************
 *
 * JAVASCRIPT
 *
 *********************/

/*********************
 * IMPORT STYLES
 *********************/

import '../scss/style.scss';
import '../scss/admin.scss';
import '../scss/login.scss';

/*********************
 * IMPORT LIBRARIES (REMOVE IF UNUSED)
 *********************/

import Swiper from 'swiper';
//import {Navigation, Pagination, Autoplay, Thumbs, EffectFade, Grid} from 'swiper/modules';

/*import {
    animateOnScroll,
    advancedSticky,
    parralax,
    svgPathFill
} from './libs/animation';*/

import './libs/cookies.js';
import {localScroll} from './libs/local-scroll';
import {reframe} from './libs/reframe';

/*********************
 * GLOBAL VARIABLES
 *********************/

let clamp;
let prevVpWidth;
let vpWidth;
let vpHeight;

const bodyElement = document.querySelector('body');
const header = document.querySelector('.header');

/*********************
 * CLAMP VARIABLE
 *********************/

function getClamp() {
    clamp = parseFloat(window.getComputedStyle(document.documentElement).getPropertyValue('font-size')) / 16;
}

getClamp();

/*********************
 * GET VIEWPORT DIMENSIONS
 *********************/

function getViewportDimensions() {
    vpWidth = window.innerWidth || document.documentElement.clientWidth;
    vpHeight = window.innerHeight || document.documentElement.clientHeight;
}

getViewportDimensions();

/*********************
 * RESIZE
 *********************/

window.addEventListener(
    'resize',
    throttle(() => {
        getViewportDimensions();
        getClamp();
        adjustMenu();
    }, 75)
);

/*********************
 * PAGE LOAD
 *********************/

document.addEventListener('DOMContentLoaded', (event) => {
    bodyElement.classList.add('js-active');

    const selectors = [
        "iframe[src*='player.vimeo.com']",
        "iframe[src*='vimeo.com']",
        "iframe[src*='youtube.com']",
        "iframe[src*='youtube-nocookie.com']",
        "iframe[src*='kickstarter.com'][src*='video.html']",
        'object',
        'embed',
    ];
    reframe(selectors.join(','));

    // Object fit polyfill for IE
    if (!Modernizr.objectfit) {
        document.querySelectorAll('.img-container').forEach((el) => {
            const imgUrl = el.querySelector('img').src;
            if (imgUrl) {
                el.style.backgroundImage = 'url(' + imgUrl + ')';
                el.classList.add('compat-object-fit');
            }
        });
    }
});

/*********************
 * FIX BODY
 *********************/

export function fixBody(bodyClass) {
    let scrollPosition = window.pageYOffset;
    bodyElement.classList.add(bodyClass);
    bodyElement.style.top = '-' + scrollPosition + 'px';
    bodyElement.setAttribute('data-scroll', String(scrollPosition));
}

/*********************
 * UNFIX BODY
 *********************/

export function unfixBody(bodyClass) {
    let scrollPosition = bodyElement.getAttribute('data-scroll');
    bodyElement.classList.remove(bodyClass);
    bodyElement.style.removeProperty('top');
    window.scrollTo(0, parseInt(scrollPosition));
}

/*********************
 * ADJUST MENU
 *********************/

function adjustMenu() {
    if (header) {
        const nav = header.querySelector('.main-nav');
        const activeSubMenu = nav.querySelector('.sub-menu.active');

        if (vpWidth < 1024 && prevVpWidth >= 1024) {

            unfixBody('menu-active');
            nav.classList.remove('active', 'sub-menu-active');
            if (activeSubMenu) {
                activeSubMenu.classList.remove('active');
            }

        } else if (vpWidth >= 1024 && prevVpWidth < 1024) {

            unfixBody('menu-active');
            nav.classList.remove('active', 'sub-menu-active');
            if (activeSubMenu) {
                activeSubMenu.classList.remove('active');
            }

        }

        prevVpWidth = vpWidth;
    }
}

/*********************
 * MENU TOGGLE
 *********************/

if (header) {
    const menuToggle = header.querySelector('.menu-toggle');
    if (menuToggle) {

        menuToggle.addEventListener('click', (e) => {
            e.preventDefault();

            const nav = document.querySelector('.main-nav');

            if (nav.classList.contains('active')) {
                nav.classList.remove('active');
                unfixBody('menu-active');
            } else {
                nav.classList.add('active');
                fixBody('menu-active');
            }
        });

    }
}

/*********************
 * SUB MENU
 *********************/

if (header) {
    const subMenuParents = header.querySelectorAll('.menu-item-has-children');
    if (subMenuParents.length > 0) {

        const nav = header.querySelector('.main-nav');

        subMenuParents.forEach((element) => {

            const link = element.querySelector('a');
            const subMenu = element.querySelector('.sub-menu')

            link.addEventListener('click', (e) => {

                if (vpWidth < 1024 && (element.classList.contains('menu-item-empty-url') || (e.clientX - link.getBoundingClientRect().left) > link.offsetWidth)) {
                    e.preventDefault();
                    nav.classList.toggle('sub-menu-active');
                    subMenu.classList.toggle('active');
                }

            });

        });

    }
}

/*********************
 * SWIPER
 *********************/

// Swiper example, docs: https://swiperjs.com/swiper-api
const swiperSlider = document.querySelector('.swiper');
if (swiperSlider) {
    const swiper = new Swiper('.swiper', {
        //modules: [Navigation, Pagination, etc.],
        loop: false,
        slidesPerView: 1,
        spaceBetween: 10,
        // Responsive breakpoints
        breakpoints: {
            // When window width is >= 480px
            480: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            // When window width is >= 640px
            640: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
        },

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    });
}

/*********************
 * LOCAL SCROLL
 *********************/

const links = document.querySelectorAll('a[href*="#"]:not([href="#"])');

if (links.length > 0) {
    links.forEach((element) => {
        const hrefUrl = new URL(element.href);

        element.addEventListener('click', (e) => {

            if (window.location.hostname === hrefUrl.hostname && window.location.pathname === hrefUrl.pathname) {
                e.preventDefault();
                const hashLink = hrefUrl.hash;

                if (hashLink) {
                    const linkTarget = document.querySelector(hashLink);
                    const spacingBetweenHeader = clamp * 16;

                    if (linkTarget) {
                        const targetTop = linkTarget.offsetTop - header.offsetHeight - spacingBetweenHeader;
                        localScroll(document.documentElement, targetTop, 1000);
                    }
                }
            }

        });
    });
}

/*********************
 * LOCAL NAV
 *********************/

const localNav = document.querySelector('.local-nav');

if (localNav) {

    let i = 0;

    document.addEventListener('DOMContentLoaded', (e) => {
        localNavPosition()
    });

    document.addEventListener('scroll', (e) => {
        localNavPosition();
    });

    function localNavPosition() {

        const items = localNav.querySelectorAll('li');
        let scrollTrigger = Math.round(window.scrollY + header.offsetHeight + (clamp * 16) + 1);
        let headingTops = [];

        items.forEach((element) => {

            const link = element.querySelector('a').getAttribute('href');
            const linkName = link.replace('#', '');
            const anchorHeading = document.getElementById(linkName);
            headingTops.push(anchorHeading.offsetTop);

        });

        const firstHeadingTop = headingTops[0];
        const lastHeadingTop = headingTops[headingTops.length - 1];

        headingTops.forEach((headingTop, index) => {

            let nextIndex = index + 1;
            let nextHeadingTop = headingTops[nextIndex];

            if (scrollTrigger >= headingTop && scrollTrigger < nextHeadingTop) {

                items[index].classList.add('active');
                i = 0;

            } else if (scrollTrigger < firstHeadingTop) {

                items[0].classList.add('active');
                i = 0;

            } else if (scrollTrigger >= lastHeadingTop) {

                if (i === 0) {
                    for (let key of headingTops.keys()) {
                        items[key].classList.remove('active');
                    }
                    items[headingTops.length - 1].classList.add('active');
                    i++;
                }

            } else {

                items[index].classList.remove('active');
                i = 0;

            }

        });

    }

}

/*********************
 * INTERSECTION OBSERVER
 *********************/

// convert this into a class for easier use in this and other projects?
/*
const options = {
    root: null, // viewport
    threshold: 0,
    rootMargin: '0px',
};

const animationTriggerObserver = new IntersectionObserver(
    (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('animateMe');

                observer.unobserve(entry.target);
            }
        });
    },
    options
);

if (document.querySelectorAll('.svgAni').length > 0) {
    const svgs = document.querySelectorAll('.svgAni');

    svgs.forEach((el) => {
        animationTriggerObserver.observe(el);
    });
}
*/

/*********************
 * EVENT FILTER
 *********************/

const eventFilter = document.getElementById('event-filter');

if (eventFilter) {

    eventFilter.querySelectorAll('input').forEach((element) => {
        element.addEventListener('change', (e) => {
            filterItems();
        });
    });

}

/*********************
 * FILTER ITEMS
 *********************/

function filterItems() {

    let filter = jQuery('#event-filter');

    setCookie('event-filter', filterToJSON('#event-filter'), 1);

    jQuery.ajax({
        url: safe.ajax_url,
        type: 'POST',
        data: 'action=bb_filter_events&' + filter.serialize() + '&n=' + safe.ajax_nonce,

        success: function (result) {
            let eventGrid = jQuery('.event-grid');
            eventGrid.html(result);
        }
    });

}

/*********************
 * FILTER TO JSON
 *********************/

function filterToJSON(selector) {

    var filterdata = {};

    jQuery.each(jQuery(selector).serializeArray(), function (_, kv) {
        kv.name = kv.name.substring(0, kv.name.length - 2);

        if (filterdata.hasOwnProperty(kv.name)) {
            filterdata[kv.name].push(parseInt(kv.value));
        } else {
            filterdata[kv.name] = [parseInt(kv.value)];
        }
    });

    return JSON.stringify(filterdata)
}

/*********************
 * COOKIES
 *********************/

export function setCookie(key, value, expiry) {
    let expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString() + ';path=/';
}

export function getCookie(key) {
    let keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

/*********************
 * HELPER FUNCTION TO THROTTLE EVENTS
 *********************/

function throttle(callback, delay) {
    let previousCall = new Date().getTime();
    return function () {
        let time = new Date().getTime();

        if (time - previousCall >= delay) {
            previousCall = time;
            callback.apply(null, arguments);
        }
    };
}